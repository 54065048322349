import ApiService from '@/services/ApiService';

const API_URL = '/api/Address/';

class PublicAddressService {
  getPostalCodeData(postalCode: string) {
    return ApiService.get(`${API_URL}PostalCodeData?postalCode=${postalCode}`);
  }
  getCountries() {
    return ApiService.get('/api/Address/Countries');
  }
  getStates(country: string) {
    return ApiService.get(`/api/Address/States?country=${country}`);
  }
}

export default new PublicAddressService();
