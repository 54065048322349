import { maxLength, minLength, required, numeric } from 'vuelidate/lib/validators';

export default {
  street: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(50),
  },
  zipCode: {
    required,
    minLength: minLength(4),
    maxLength: maxLength(5),
    numeric,
  },
  city: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(25),
  },
  country: {
    required,
    minLength: minLength(3),
  },
  state: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(25),
  },
  houseNumber: {
    required,
    houseNumber: (value: string) => /^[A-Za-z0-9 ]*$/.test(value),
    maxLength: maxLength(5),
  },
  latitude: {},
  longitude: {},
};
