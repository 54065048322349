











































































































































































































































import Vue from 'vue';
import { required, email, minLength, sameAs, maxLength } from 'vuelidate/lib/validators';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import ApiService from '@/services/ApiService';
import PasswordComplexityValidator from '@/services/validators/PasswordComplexityValidator';
import ToastMessage from '@/models/ToastMessage';
import { validationMixin } from 'vuelidate';
import AddressFormValidations from '@/validations/AddressFormValidations';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import Notification from '@/components/shared/Notification.vue';
import RegistrationService from '@/services/users/RegistrationService';
import { MetaInfo } from 'vue-meta';

const validations = {
  user: {
    ...AddressFormValidations,
    email: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(50),
      email,
      emailIsUnique: (value: string) => {
        return new Promise<boolean>((resolve) => {
          ApiService.get('/api/auth/emailExists?email=' + value).then(
            (data) => {
              resolve(!data.value);
            },
            () => {
              resolve(false);
            }
          );
        });
      },
    },
    displayname: {
      minLength: minLength(5),
      maxLength: maxLength(50),
      required,
      displayNameIsUnique: (value: string) => {
        return new Promise<boolean>((resolve) => {
          if (value === '') {
            resolve(true);
            return;
          }
          ApiService.get('/api/auth/displayNameExists?name=' + value).then(
            (data) => {
              resolve(!data.value);
            },
            () => {
              resolve(true);
            }
          );
        });
      },
    },
    privacyCheck: {
      checked: (val: boolean) => val || false,
    },
    businessTerms: {
      checked: (val: boolean) => val || false,
    },
    acceptBooking: {
      checked: (val: boolean) => val || false,
    },
    allowEmailForPersonalOffersAt: {},
    allowEmailForFeedbackAt: {},
    firstname: {
      required,
      maxLength: maxLength(25),
    },
    lastname: {
      required,
      maxLength: maxLength(25),
    },
    password: {
      minLength: minLength(12),
      maxLength: maxLength(50),
      required,
      PasswordComplexityValidator,
    },
    passwordConfirmation: {
      minLength: minLength(12),
      maxLength: maxLength(50),
      sameAsPassword: sameAs('password'),
      required,
    },
    companyName: {},
    companyLegalType: {},
    companyLegalName: {},
  },
};

export default Vue.extend({
  components: { ValidatedInput, AddressForm, ValidatedCheckbox, Notification, ValidatedSelect },
  mixins: [validationMixin],
  validations() {
    var vals = { ...validations };
    if (!!this.user.companyName) {
      vals.user.companyLegalType = { required };
    } else {
      vals.user.companyLegalType = {};
    }
    if (!!this.user.companyLegalType) {
      vals.user.companyName = { required, maxLength: maxLength(50) };
    } else {
      vals.user.companyName = { maxLength: maxLength(50) };
    }
    if (this.user.companyLegalType == 'Other') {
      vals.user.companyLegalName = { required };
    } else {
      vals.user.companyLegalName = {};
    }
    return vals;
  },
  mounted() {
    this.setBreadCrumb();
  },
  data() {
    return {
      loading: false,
      successful: false,
      message: '',
      user: {
        displayname: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        firstname: '',
        lastname: '',
        street: '',
        zipCode: '',
        city: '',
        country: 'Deutschland',
        state: '',
        companyName: '',
        houseNumber: '',
        privacyCheck: false,
        businessTerms: false,
        acceptBooking: false,
        allowEmailForPersonalOffersAt: false,
        allowEmailForFeedbackAt: false,
        companyLegalName: '',
        companyLegalType: '',
        companyLegalTypes: [],
        promotionCode: '',
      },
      showNotification: false,
    };
  },
  metaInfo(): MetaInfo {
    return { title: this.$t('pages.auth.registerOwner.meta.title').toString() };
  },
  computed: {
    loggedIn(): boolean {
      return this.$store.state.AuthStoreModule.status.loggedIn;
    },
    customerRegisterLink(): string {
      let props = this.$router.resolve({ name: 'RegisterCustomer' });
      return props.href;
    },
    checkPrivacyChecked(): boolean {
      return this.user.privacyCheck && this.user.businessTerms && this.user.acceptBooking && !this.$v.$invalid;
    },
    companyLegalTypeOptions(): { value: string; label: string }[] {
      return [
        { value: '', label: '' },
        { value: 'Einzelunternehmen', label: this.$t('enums.companyLegalTypes.Einzelunternehmen').toString() },
        { value: 'GbR', label: this.$t('enums.companyLegalTypes.GbR').toString() },
        { value: 'KG', label: this.$t('enums.companyLegalTypes.KG').toString() },
        { value: 'OHG', label: this.$t('enums.companyLegalTypes.OHG').toString() },
        { value: 'UG', label: this.$t('enums.companyLegalTypes.UG').toString() },
        { value: 'GmbH', label: this.$t('enums.companyLegalTypes.GmbH').toString() },
        { value: 'Other', label: this.$t('enums.companyLegalTypes.Other').toString() },
      ];
    },
    companyLegalType(): string {
      return this.user.companyLegalType;
    },
    companyName(): string {
      return this.user.companyName;
    },
    isCompanyLegalNameDisabled(): boolean {
      return this.user.companyLegalType == 'Other';
    },
    maintenanceMode(): boolean {
      return process.env.VUE_APP_MAINTENANCE_MODE == 'true';
    },
  },
  watch: {
    companyLegalType: {
      immediate: true,
      handler() {
        if (this.user.companyLegalType == 'Other') {
          this.$v.user.companyLegalName?.$touch();
        }
        if (this.user.companyLegalType) {
          this.$v.user.companyName?.$touch();
        }
      },
    },
    companyName: {
      immediate: true,
      handler() {
        if (this.user.companyName) {
          this.$v.user.companyLegalType?.$touch();
        } else {
          this.$v.user.companyLegalType?.$reset();
          this.$v.user.companyName?.$reset();
        }
      },
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.auth.registerOwner.breadcrumb.last' },
      ]);
    },
    checkIfValid(fieldName: string) {
      const field = this.$v.user[fieldName];
      if (field === undefined || !field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === '');
    },

    handleCloseNotification() {
      this.$router.push('/auth/login');
    },

    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.message = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        RegistrationService.registerOwner({ ...this.user }).then(
          () => {
            this.showNotification = true;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.message = error;
          }
        );
      }
    },
  },
});
