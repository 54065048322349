import ApiService from '@/services/ApiService';

const API_URL = '/api/auth/';

class RegistrationService {
  registerCustomer(user: {
    allowEmailForPersonalOffersAt: string | boolean;
    allowEmailForFeedbackAt: string | boolean;
  }) {
    user.allowEmailForPersonalOffersAt = String(user.allowEmailForPersonalOffersAt);
    user.allowEmailForFeedbackAt = String(user.allowEmailForFeedbackAt);
    return ApiService.post(`${API_URL}registerCustomer`, user);
  }

  registerOwner(user: { allowEmailForPersonalOffersAt: string | boolean; allowEmailForFeedbackAt: string | boolean }) {
    user.allowEmailForPersonalOffersAt = String(user.allowEmailForPersonalOffersAt);
    user.allowEmailForFeedbackAt = String(user.allowEmailForFeedbackAt);
    return ApiService.post(`${API_URL}registerOwner`, user);
  }
}
export default new RegistrationService();
