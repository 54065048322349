



























































































import Vue from 'vue';
import ValidatedInput from './ValidatedInput.vue';
import ValidatedSelect from './ValidatedSelect.vue';
import PublicAddressService from '@/services/public/PublicAddressService';
import SelectDto from '@/models/SelectDto';

class Country {
  name!: string;
  iso!: string;
}

export default Vue.extend({
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  components: { ValidatedInput, ValidatedSelect },
  data() {
    return {
      countries: [] as Country[],
      states: [],
    };
  },
  computed: {
    countriesSelect(): SelectDto[] {
      const countries = this.countries.map((c: Country) => ({
        label: c.name,
        value: c.name,
      }));
      return [{ label: '', value: '' }, ...countries];
    },

    stateSelect(): SelectDto[] {
      const states = this.states.map((c: string) => ({
        label: c,
        value: c,
      }));
      return [{ label: '', value: '' }, ...states];
    },
  },
  methods: {
    onChangeCountry(event: Event) {
      const selectField = event.target as HTMLSelectElement;
      this.loadStates(selectField.value);
      this.onChange();
    },

    onChange() {
      this.$emit('change');
      this.$emit('update:value', this.value);
    },

    loadStates(country: string) {
      if (country === 'Deutschland') {
        PublicAddressService.getStates(country).then(
          (data) => {
            if (data.value === null) {
              // No valid Country
              return;
            } else {
              this.states = data.value;
              // Select first state
              if (data.value.length > 0 && this.value.state === '') {
                this.value.state = data.value[0];
              }
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
      } else {
        this.states = [];
      }
    },

    loadPostalCodeData(postalCode: string) {
      if (postalCode.length == 5) {
        PublicAddressService.getPostalCodeData(postalCode).then(
          (data) => {
            if (data.value === null) {
              // No valid PostalCode
              return;
            } else {
              this.field.city.$model = data.value.city;
              this.field.state.$model = data.value.state;
            }
          },
          (error) => {
            console.log('error', error);
          }
        );
      }
    },
  },

  mounted() {
    PublicAddressService.getCountries()
      .then(
        (data) => {
          this.countries = data.value.filter((c: Country) => c.name && c.name.length > 0);
          if (!!this.value?.country) {
            this.loadStates(this.field.country.$model);
          }
        },
        (error) => {
          console.log('error', error);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  },
});
